import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "@/i18n/i18n-client";
import { Field, Form } from "react-final-form";
import { RequiredSpan } from ".././Contact/styled";
import { PrimaryButton } from "@/components/Button";
import { Lock } from "@/assets/Icons/Lock";
import { Mail } from "@/assets/Icons/Mail";
import InputPhone, { PhoneInput } from "@/shared/globals/UiElements/InputPhone";
import LoginSwitcher from "../../components/Authentication/LoginSwitcher";
import {
  LoginFormProps,
  LoginFormValues,
  LoginMethod,
  TemplateElement,
} from "../../../types";
import Flex from "@/shared/globals/UiElements/Flex";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { Link, useSearchParams } from "@/i18n/i18n-navigation";

const LoginForm: TemplateElement<LoginFormProps> = ({
  activeTab,
  setActiveTab,
  onSubmit,
  isError,
  setIsError,
  focusOnError,
}) => {
  const intl = useIntl();
  const searchParams = useSearchParams();

  return (
    <Form<LoginFormValues>
      onSubmit={onSubmit}
      validate={(values) => validate(values, activeTab)}
      decorators={[focusOnError]}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex column spacing="xl">
            <Flex
              column
              alignItems="center"
              justifyContent="center"
              spacing="s"
            >
              <h1 className="m-0 text-gray-900 font-semibold">
                <FormattedMessage
                  defaultMessage="Sign-in to your account"
                  id="AD4Lep"
                />
              </h1>
              <h3 className="text-gray-600 text-base font-normal m-0">
                <FormattedMessage
                  defaultMessage="Welcome back! Please enter your details."
                  id="mvJYlv"
                />
              </h3>
            </Flex>
            <LoginSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === LoginMethod.EMAIL && (
              <label className="text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="Email" id="sy+pv5" />
                <Field name="email">
                  {({ input, meta: { error, touched } }) => (
                    <div className="mt-1.5">
                      <InputWithIcon
                        {...input}
                        type="email"
                        autoComplete="email"
                        prefix={<Mail />}
                        className={
                          (error && touched) || isError ? "invalid" : ""
                        }
                        onChange={(event) => {
                          setIsError(false);
                          input.onChange(event);
                        }}
                        placeholder={intl.formatMessage({
                          defaultMessage: "Enter your email",
                          id: "5MDGuM",
                        })}
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                    </div>
                  )}
                </Field>
              </label>
            )}
            {activeTab === LoginMethod.PHONE && (
              <label className="text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="Phone no." id="1SphRN" />
                <Field<PhoneInput> name="phone">
                  {(fieldProps) => (
                    <InputPhone
                      {...fieldProps}
                      className={isError ? "invalid" : ""}
                      onChange={(event: PhoneInput) => {
                        setIsError(false);
                        fieldProps.input.onChange(event);
                      }}
                    />
                  )}
                </Field>
              </label>
            )}
            <Flex column alignItems="flex-end">
              <label className="w-full text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="Password" id="5sg7KC" />
                <Field name="password">
                  {({ input, meta: { error, touched } }) => (
                    <div className="mt-1.5">
                      <InputWithIcon
                        {...input}
                        type="password"
                        autoComplete="current-password"
                        prefix={<Lock />}
                        className={
                          (error && touched) || isError ? "invalid" : ""
                        }
                        onChange={(event) => {
                          setIsError(false);
                          input.onChange(event);
                        }}
                        placeholder="••••••••"
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                      {isError && (
                        <RequiredSpan>
                          <FormattedMessage
                            defaultMessage="Invalid credentials"
                            id="M77Nuo"
                          />
                        </RequiredSpan>
                      )}
                    </div>
                  )}
                </Field>
              </label>
              <PrimaryButton compact reversed>
                <Link href="/reset-password">
                  <FormattedMessage
                    defaultMessage="Forgot password?"
                    id="V/JHlm"
                  />
                </Link>
              </PrimaryButton>
            </Flex>

            <PrimaryButton
              type="submit"
              fullWidth
              isLoading={submitting}
              rounded
            >
              <FormattedMessage defaultMessage="Login" id="AyGauy" />
            </PrimaryButton>

            <Flex justifyContent="center" spacing="xs">
              <div className="text-gray-600 text-sm">
                <FormattedMessage
                  defaultMessage="Don't Have an Account?"
                  id="kXiqHd"
                />
              </div>
              <PrimaryButton compact reversed>
                <Link href={`/registration?${searchParams.toString()}`}>
                  <FormattedMessage
                    defaultMessage="Create Account"
                    id="5JcXdV"
                  />
                </Link>
              </PrimaryButton>
            </Flex>
          </Flex>
        </form>
      )}
    />
  );
};

export default LoginForm;

/**
 *
 * Functions
 *
 */

const validate = (values: LoginFormValues, activeTab: LoginMethod) => {
  const errors: Partial<{ [key in keyof LoginFormValues]: ReactElement }> = {};

  if (
    activeTab === LoginMethod.EMAIL &&
    (!values?.email?.trim() ||
      !values?.email?.match(
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
      ))
  ) {
    errors.email = (
      <FormattedMessage
        defaultMessage="Please enter a valid email address"
        id="oLpv29"
      />
    );
  }

  if (activeTab === LoginMethod.PHONE && !values?.phone?.isValid) {
    errors.phone = (
      <FormattedMessage
        defaultMessage="Please enter a valid phone number"
        id="FPcbu0"
      />
    );
  }

  if (!values?.password) {
    errors.password = (
      <FormattedMessage
        defaultMessage="Please enter your password"
        id="iiYrag"
      />
    );
  }

  return errors;
};
