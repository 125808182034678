const Lock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1667 9.16667V6.66667C14.1667 4.36548 12.3012 2.5 10 2.5C7.69881 2.5 5.83333 4.36548 5.83333 6.66667V9.16667M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V13.1667C17.5 11.7665 17.5 11.0665 17.2275 10.5317C16.9878 10.0613 16.6054 9.67883 16.135 9.43915C15.6002 9.16667 14.9001 9.16667 13.5 9.16667H6.5C5.09987 9.16667 4.3998 9.16667 3.86502 9.43915C3.39462 9.67883 3.01217 10.0613 2.77248 10.5317C2.5 11.0665 2.5 11.7665 2.5 13.1667V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { Lock };
