import styled from "styled-components";
import { H3 } from "../../../../shared/globals";
import { themeColor } from "../../../../shared/styles-utils";
import { RequiredSpan } from "../../../../components/Checkout/Information/styled";

export const StyledH3 = styled(H3)`
  color: ${themeColor("primary")};
`;

export { RequiredSpan };
