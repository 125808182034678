const AddUser = () => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.75" width="36" height="36" rx="18" fill="#F2F4F7" />
    <path
      d="M23.7501 24.6667V24.0417C23.7501 22.0857 22.1644 20.5 20.2084 20.5H13.9584C12.0024 20.5 10.4167 22.0857 10.4167 24.0417V24.6667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="17.0833"
      cy="13.8333"
      r="3.33333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5834 15.5V18.8333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2501 17.1667H22.9167"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { AddUser };
