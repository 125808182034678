import React, { Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "@/i18n/i18n-client";
import styled, { css } from "styled-components";
import { Mail } from "@/assets/Icons/Mail";
import { Phone } from "@/assets/Icons/Phone";
import Flex from "@/shared/globals/UiElements/Flex";

type LoginMethod = "EMAIL" | "PHONE";

interface LoginSwitcher {
  activeTab: LoginMethod;
  setActiveTab: Dispatch<SetStateAction<LoginMethod>>;
}

const LoginSwitcher = ({ activeTab, setActiveTab }: LoginSwitcher) => {
  return (
    <Flex className="border rounded-md" spacing="none">
      <StyledFlexRow
        isActive={activeTab === "EMAIL"}
        onClick={() => setActiveTab("EMAIL")}
        spacing="xs"
        alignItems="center"
      >
        <Mail />
        <FormattedMessage defaultMessage="E-mail" id="tkwCac" />
      </StyledFlexRow>
      <StyledFlexRow
        isActive={activeTab === "PHONE"}
        onClick={() => setActiveTab("PHONE")}
        spacing="xs"
        alignItems="center"
      >
        <Phone />
        <FormattedMessage defaultMessage="Phone" id="O95R3Z" />
      </StyledFlexRow>
    </Flex>
  );
};

export default LoginSwitcher;

const StyledFlexRow = styled(Flex)<{ isActive: boolean }>`
  width: 50%;
  justify-content: center;
  padding: 10px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.primary}33;
    `}
`;
