export * from "./FooterProps";
export * from "./HomeGallerySectionProps";
export * from "./HomeContentSectionProps";
export * from "./ProductCardProps";
export * from "./HomePageProps";
export * from "./ShopPageProps";
export * from "./ProductListProps";
export * from "./SimpleProductProps";
export * from "./ProductGalleryProps";
export * from "./ProductDetailsProps";
export * from "./ContactPageProps";
export * from "./StaticPageProps";
export * from "./LoginPageProps";
export * from "./LoginFormProps";
export * from "./RegistrationPageProps";
export * from "./RegistrationFormProps";
export * from "./ResetPasswordPageProps";
export * from "./ResetPasswordFormProps";
export * from "./ForgotPasswordFormProps";
export * from "./CartPageProps";
export * from "./CollectionPageProps";
export * from "./UserOrdersPageProps";
export * from "./UserAddressesPageProps";
export * from "./UserAccountPageProps";
export * from "./CustomProductProps";
export * from "./VariantsSectionProps";
export * from "./HeroProps";
export * from "./SiteLayoutProps";
export * from "./NavBarProps";
export * from "./SearchPageProps";
export * from "./RatingAndReviewsProps";
export * from "./SectionCardWrapperProps";
export * from "./SectionHeadingProps";
export * from "./GridGallerySectionProps";
export * from "./StorePageProps";
export * from "./FilterAndSortProps";
export * from "./AttributeValueCardProps";
export * from "./BannerCardProps";
export * from "./CollectionCardProps";
export * from "./ProductPageProps";
export * from "./SideCartProps";
export * from "./ProfileLayoutProps";
export * from "./PageHeaderProps";
export * from "./CartTableProps";
export * from "./CartItemProps";
export * from "./CouponProps";
export * from "./MobileNavigationProps";
export * from "./InfoBarProps";
export * from "./SectionContentProps";
export * from "./DiscountBreakdownProps";
export * from "./MeLayoutProps";
export * from "./CheckoutLayoutProps";

export type TemplateElement<T> = React.FC<T>;
